import AuthedHeader from "../components/AuthedHeader";
import AccountInfo from "../components/AccountInfo";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import ipAddress from "../config";
import logo from "../img/logo.png";

export default function OfferWallPage() {
  const [userBalance, setUserBalance] = useState(0);
  const [userName, setUserName] = useState("");
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUserName(user.robloxUserName);
    setUserBalance(user.balance);
    setAvatar(user.avatar);
  }, []);

  async function completeAdSurvey() {
    const updatedBalance = userBalance + 10;
    setUserBalance(updatedBalance);

    console.log("sending: ", updatedBalance);
    let data = {
      balance: updatedBalance,
    };

    let response = await fetch(`${ipAddress}:3001/api/users/${userName}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(
        `Request to create user failed with status ${response.status}`
      );
    }
    const user = JSON.parse(localStorage.getItem("user"));
    user.balance = updatedBalance;
    localStorage.setItem("user", JSON.stringify(user));
  }

  return (
    <div className="offer-wall-page">
      <AuthedHeader />
      <Container fluid className="p-0">
        <div className="text-center mt-5">
          <Image src={logo} alt="Logo" height="200" width="200" />
        </div>
        <Row className="justify-content-center mx-0">
          <Col md={8}>
            {/* Display the user's Roblox balance */}
            <AccountInfo
              username={userName}
              avatar={avatar}
              balance={userBalance}
            />

            {/* Ad Survey Section */}
            <div className="ad-survey-section mt-4">
              <h2>Ad Surveys</h2>
              <p className="mb-4">Complete surveys to earn Robux:</p>

              {/* Example ad survey */}
              <Card>
                <Card.Body>
                  <Card.Title>Survey Title</Card.Title>
                  <Card.Text>Description of the survey.</Card.Text>
                  <Button variant="primary" onClick={completeAdSurvey}>
                    Complete Survey
                  </Button>
                </Card.Body>
              </Card>

              {/* Add more ad surveys here */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
