import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import OfferWallPage from "./pages/OfferWallPage";
import AuthContext from "./contexts/AuthContext";
import "./styles/App.css";

function App() {
  const { isAuthed } = useContext(AuthContext);

  return (
    <div className="App">
      <Router>
        <Routes>
          {isAuthed ? (
            <>
              <Route path="/" element={<Navigate to="/offerwall" />} />
              <Route path="/offerwall" element={<OfferWallPage />} />
            </>
          ) : (
            <>
              <Route path="/offerwall" element={<Navigate to="/" />} />
              <Route path="/" element={<HomePage />} />
            </>
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
