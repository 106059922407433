import React, { useContext, useState } from "react";
import Header from "../components/Header";
import "../styles/HomePage.css";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import AuthContext from "../contexts/AuthContext";
import Cookies from "js-cookie";
import ipAddress from "../config";
import logo from "../img/logo.png";

export default function HomePage() {
  const [userName, setUserName] = useState("");
  const { setIsAuthed } = useContext(AuthContext);

  function onInput({ target: { value } }) {
    setUserName(value);
  }

  async function handleFormSubmit(e) {
    e.preventDefault();
    console.log(ipAddress);
    try {
      let response = await fetch(
        `${ipAddress}:3001/api/users/login/${userName}`
      );

      // If get user doesnt exist in database, creating a new entry with that username
      if (!response.ok) {
        // get roblox id from roblox api... TODO
        let data = {
          robloxID: "0",
          robloxUserName: userName,
          balance: 0,
          avatar: "0",
        };
        response = await fetch(`${ipAddress}:3001/api/users`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          throw new Error(
            `Request to create user failed with status ${response.status}`
          );
        }
      }

      const result = await response.json();
      console.log("Result from sign-in", result);
      console.log(result.token);
      await Cookies.set("authToken", result.token, { expires: 7 });
      await localStorage.setItem("user", JSON.stringify(result.user));
      console.log("Cookie: ", Cookies.get("authToken"));
      setUserName("");
      setIsAuthed(true);
    } catch (error) {
      console.log(error);
      if (error.message.includes("404")) {
        alert("User not found");
      } else if (error.message.includes("429")) {
        alert("Too many requests, please try again later.");
      }
    }
  }

  return (
    <div className="home-page">
      <Header />
      <Container fluid className="p-0">
        <div className="text-center mt-5">
          <Image src={logo} alt="Logo" height="200" width="200" />
        </div>
        <Row
          className="d-flex justify-content-center"
          style={{ height: "25vh" }}
        >
          <Col md={6} lg={4} className="login-form-background">
            <Form onSubmit={handleFormSubmit}>
              <Form.Group className="mb-3" controlId={userName}>
                <Form.Label>Roblox Username</Form.Label>
                <Form.Control
                  onChange={onInput}
                  value={userName}
                  type="text"
                  placeholder="Enter Username"
                />
              </Form.Group>

              <Button variant="danger" type="submit" className="mt-5">
                Login with Roblox
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
