import React from "react";
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import "../styles/styles.css";

function AccountInfo({ username, avatar, balance }) {
  return (
    <Card className="text-center mt-4">
      <Card.Body>
        <Row className="">
          <Col sm={6}>
            <Card.Title className="card-title">{username}</Card.Title>
            <Image src={avatar} roundedCircle width={100} height={100} />
          </Col>
          <Col sm={6} className="d-flex align-items-center">
            <Card.Text className="card-text-robux">{balance} Robux</Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default AccountInfo;
