import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Cookies from "js-cookie";
import Image from "react-bootstrap/Image";
import AuthContext from "../contexts/AuthContext";
import logo from "../img/logo.png";

function Header() {
  const { setIsAuthed } = useContext(AuthContext);

  function signOut() {
    Cookies.remove("authToken");
    localStorage.removeItem("user");
    setIsAuthed(false);
  }

  return (
    <Navbar expand="lg" className="navbar navbar-dark bg-dark">
      <Container fluid>
        <Navbar.Brand href="#home">
          <Image src={logo} alt="Logo" width="50" height="50" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Button variant="danger" onClick={signOut}>
          Sign Out
        </Button>
      </Container>
    </Navbar>
  );
}

export default Header;
