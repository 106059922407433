import React, { createContext, useEffect, useState } from "react";

// new context object
const AuthContext = createContext();

// create provider component
export function AuthProvider(props) {
  const [isAuthed, setIsAuthed] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      console.log(user);
      setIsAuthed(true);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthed, setIsAuthed }}>
      {props.children}
    </AuthContext.Provider>
  );
}

// Export the context object as a default export
export default AuthContext;
